<template>
  <div class="services">
    <v-img
      :aspect-ratio="16/2"
      position="bottom"
      :src="require('@/assets/clients/media/banner/services-bg.jpg')"
    >
      <v-container class="align-center fill-height">
        <v-card-text class="display-1 white--text font-weight-bold">Services</v-card-text>
      </v-container>
    </v-img>
    <template v-if="currentUserRole === 'admin' ">
      <v-btn
        v-if="!editMode"
        @click="activeEditMode()"
        class="mr-12"
        large
        right
        dark
        color="teal"
        absolute
        fab
      >
        <v-icon>mdi-lead-pencil</v-icon>
      </v-btn>
      <v-btn v-else @click="save()" large dark class="mr-12" color="teal" right absolute fab>
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </template>
    <!-- Section S-->
    <v-row v-if="services">
      <v-container>
        <v-row>
          <!-- Text Section  -->
          <v-col v-if="!editMode" cols="12" md="6">
            <v-card flat>
              <v-card-title
                v-text="section_3.title"
                class="primary--text headline font-weight-bold"
              ></v-card-title>
              <v-card-text class="text-justify" v-html="section_3.description"></v-card-text>
            </v-card>
          </v-col>
          <v-col v-else cols="12" md="6">
            <v-card-text>
              <v-text-field v-model="section_3.title" outlined></v-text-field>
              <quill-editor v-model="section_3.description" :options="editorOption"></quill-editor>
            </v-card-text>
          </v-col>
          <!-- Text Section  -->
          <!-- Image Section  -->
          <v-col cols="12" md="6">
            <v-img max-height="250" :src="section_3.img"></v-img>
            <!-- Image Editing -->
            <v-card class="mt-6" flat v-if="editMode" outlined>
              <v-card-text v-if="progress === 0">
                <v-file-input accept="image/*" label="Upload Image" v-model="selectedImg"></v-file-input>
                <v-btn @click="changeImageS3(section_3.img)" block color="white">Change</v-btn>
              </v-card-text>
              <v-card-text v-else>
                <v-progress-circular size="56" v-model="progress">{{ parseInt(progress) }}</v-progress-circular>
              </v-card-text>
            </v-card>
            <!-- Image Editing -->
          </v-col>
          <!-- Image Section  -->
        </v-row>
        <v-row>
          <!-- Text Section  -->
          <v-col v-if="!editMode" cols="12">
            <v-card flat>
              <v-card-title
                v-text="section_4.title"
                class="primary--text headline font-weight-bold"
              ></v-card-title>
              <v-card-text v-html="section_4.description"></v-card-text>
            </v-card>
            <v-expansion-panels flat outline accordion>
              <v-expansion-panel v-for="(item,i) in section_4.items" :key="i">
                <v-expansion-panel-header class="font-weight-bold primary--text">{{item.title}}</v-expansion-panel-header>
                <v-expansion-panel-content class="text-justify">
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col v-else cols="12">
            <v-card-text>
              <v-text-field v-model="section_4.title" outlined></v-text-field>
              <quill-editor
                class="text-justify"
                v-model="section_4.description"
                :options="editorOption"
              ></quill-editor>
            </v-card-text>
            <v-expansion-panels flat accordion>
              <v-expansion-panel v-for="(item,i) in section_4.items" :key="i">
                <v-expansion-panel-header>
                  <v-btn
                    @click="removeListItem(item,i)"
                    icon
                    absolute
                    right
                    top
                    dark
                    x-small
                    color="error"
                  >
                    <v-icon>clear</v-icon>
                  </v-btn>
                  <v-text-field v-model="item.title" outlined></v-text-field>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <quill-editor v-model="item.description" :options="editorOption"></quill-editor>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-divider></v-divider>
              <v-btn block text @click="addItem" color="primary">Add New</v-btn>
            </v-expansion-panels>
          </v-col>
          <!-- Text Section  -->

          <!-- Text Section  -->
          <v-col v-if="!editMode" cols="12">
            <v-card flat>
              <v-card-title
                v-text="section_5.title"
                class="primary--text headline font-weight-bold"
              ></v-card-title>
              <v-card-text class="text-justify" v-html="section_5.description"></v-card-text>
            </v-card>
          </v-col>
          <v-col v-else cols="12">
            <v-card-text>
              <v-text-field v-model="section_5.title" outlined></v-text-field>
              <quill-editor v-model="section_5.description" :options="editorOption"></quill-editor>
            </v-card-text>
          </v-col>
          <!-- Text Section  -->
        </v-row>
      </v-container>
    </v-row>
    <!-- Section E-->
    <!-- Section S-->
    <v-row class="white" v-if="services">
      <v-container>
        <v-row>
          <!-- Text Section  -->
          <v-col v-if="!editMode" cols="12" md="6">
            <v-card flat>
              <v-card-title v-text="section1.title" class="primary--text headline font-weight-bold"></v-card-title>
              <v-card-text class="text-justify" v-html="section1.description"></v-card-text>
            </v-card>
          </v-col>
          <v-col v-else cols="12" md="6">
            <v-card-text>
              <v-text-field v-model="section1.title" outlined></v-text-field>
              <quill-editor v-model="section1.description" :options="editorOption"></quill-editor>
            </v-card-text>
          </v-col>
          <!-- Text Section  -->
          <!-- Image Section  -->
          <v-col cols="12" md="6">
            <v-img max-height="250" :src="section1.mainImg"></v-img>
            <!-- Image Editing -->
            <v-card class="mt-6" flat v-if="editMode" outlined>
              <v-card-text v-if="progress === 0">
                <v-file-input accept="image/*" label="Upload Image" v-model="selectedImg"></v-file-input>
                <v-btn @click="changeImageS1(section1.mainImg)" block color="white">Change</v-btn>
              </v-card-text>
              <v-card-text v-else>
                <v-progress-circular size="56" v-model="progress">{{ parseInt(progress) }}</v-progress-circular>
              </v-card-text>
            </v-card>
            <!-- Image Editing -->
          </v-col>
          <!-- Image Section  -->
        </v-row>
      </v-container>
    </v-row>
    <!-- Section E-->
    <!-- Section S-->
    <v-row v-if="services">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-img max-height="250" :src="section2.mainImg"></v-img>
            <v-card class="mt-6" v-if="editMode">
              <v-card-text v-if="progress === 0">
                <v-file-input accept="image/*" label="Upload Image" v-model="selectedImg"></v-file-input>
                <v-btn @click="changeImageS2(section2.mainImg)" block light color="white">Change</v-btn>
              </v-card-text>
              <v-card-text v-else>
                <v-progress-circular size="56" v-model="progress">{{ parseInt(progress) }}</v-progress-circular>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="!editMode" cols="12" md="6">
            <v-card-title v-text="section2.title" class="primary--text headline font-weight-bold"></v-card-title>
            <v-card-text class="text-justify" v-html="section2.description"></v-card-text>
          </v-col>
          <v-col v-else cols="12" md="6">
            <v-card-text>
              <v-text-field v-model="section2.title" outlined></v-text-field>
              <quill-editor v-model="section2.description" :options="editorOption"></quill-editor>
            </v-card-text>
          </v-col>
        </v-row>

        <Snackbar :snackbar="snackbar" />
      </v-container>
    </v-row>
    <!-- Section E-->
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters, mapActions } from "vuex";
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large"] }],
            ["bold", "italic"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"]
          ]
        }
      },
      progress: 0,
      snackbar: {
        isActive: false, //Activator
        x: "", // left or right or ""
        y: "", // top or bottom or ""
        color: "", // Any Color or ""
        mode: null, //vertical or multi-line or null
        // timeout: null, // set timeout Ex: 60000
        text: "" //Error Message
      },
      editMode: false,
      selectedImg: null,
      uploading: ""
    };
  },
  computed: {
    ...mapGetters(["currentUserRole", "services"]),
    section1() {
      return this.services.section1;
    },
    section2() {
      return this.services.section2;
    },
    section_3() {
      return this.services.section_3;
    },
    section_4() {
      return this.services.section_4;
    },
    section_5() {
      return this.services.section_5;
    }
  },
  methods: {
    ...mapActions(["update", "bind"]),
    removeListItem(data, index) {
      this.section_4.items.splice(index, 1);
    },
    addItem() {
      this.section_4.items.push({
        title: "Your title",
        description: "Enter Description"
      });
      // this.section_4.items[index] = data;
    },
    changeImageS1(imageUrl) {
      this.uploading = "section1Img";
      if (this.selectedImg != null) {
        if (imageUrl != "") {
          let image = firebase.storage().refFromURL(imageUrl);
          // Delete the file from Firebase Storage
          image.delete();
        }
        this.uploadImage();
      }
    },
    changeImageS2(imageUrl) {
      this.uploading = "section2Img";
      if (this.selectedImg != null) {
        if (imageUrl != "") {
          let image = firebase.storage().refFromURL(imageUrl);
          // Delete the file from Firebase Storage
          image.delete();
        }
        this.uploadImage();
      }
    },
    changeImageS3(imageUrl) {
      this.uploading = "section3Img";
      if (this.selectedImg != null) {
        if (imageUrl != "") {
          let image = firebase.storage().refFromURL(imageUrl);
          // Delete the file from Firebase Storage
          image.delete();
        }
        this.uploadImage();
      }
    },
    fetchServices() {
      if (this.services == null) {
        this.bind({
          collection: "pages",
          doc: "services",
          data: "services"
        });
      }
    },
    save() {
      this.update({
        collection: "pages",
        doc: "services",
        data: {
          section1: this.section1,
          section2: this.section2,
          section_3: this.section_3,
          section_4: this.section_4,
          section_5: this.section_5
        }
      }).then(() => {
        this.snackbar.text = "Uploaded successfully";
        this.snackbar.color = "success";
        this.snackbar.isActive = true;
        this.dialog = false;
        this.editMode = false;
      });
    },
    activeEditMode() {
      if (this.currentUserRole === "admin") {
        this.editMode = true;
      }
    },
    // uploadImage,
    uploadImage() {
      if (this.selectedImg) {
        // console.log(image.target.files[0])
        let file = this.selectedImg;
        let time = Date.now();
        // File or Blob named mountains.jpg
        let storageRef = firebase
          .storage()
          .ref("services/" + time + "_" + file.name);

        // Create the file metadata
        let metadata = {
          contentType: "image/jpeg"
        };
        // Upload file and metadata to the object 'images/mountains.jpg'
        let uploadTask = storageRef.put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          snapshot => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            // console.log("Upload is " + this.progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log("Upload is running");
                break;
            }
          },
          error => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              // console.log('File available at', downloadURL);
              this.progress = 0;
              this.selectedImg = null;

              this.uploading == "section1Img"
                ? (this.section1.mainImg = downloadURL)
                : this.uploading == "section2Img"
                ? (this.section2.mainImg = downloadURL)
                : this.uploading == "section3Img"
                ? (this.section_3.img = downloadURL)
                : "";
              this.uploading = "";

              this.snackbar.text = "Uploaded successfully";
              this.snackbar.color = "success";
              this.snackbar.isActive = true;
              this.dialog = false;
            });
          }
        );
      } else {
        this.snackbar.text = "Please select an image";
        this.snackbar.color = "warning";
        this.snackbar.isActive = true;
      }
    }
  },
  created() {
    this.fetchServices();
  }
};
</script>
